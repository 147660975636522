/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2023-06-30 13:52:18
 * @LastEditors: Nick juntong.wu@travel-easy.com
 * @LastEditTime: 2024-07-10 16:40:02
 * @FilePath: /base-frontend/src/core/directive/function/user.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { computed } from "vue";
import { CommonLanguageType } from "@/core/directive/type/common";
import { RouteLocationNormalizedLoaded, Router } from "vue-router";
import { CommonModuleRouter } from "@/core/directive/interface/common";

export const getUserRouteType = computed(() => {
  return (route) => {
    const name = route.name as string;
    return name.match("user-management-system-users");
  };
});

export const getUserRoute = computed(() => {
  return (route) => {
    if (getUserRouteType.value(route)) {
      return "/user-management/system-users/";
    }
    return "/user-management/saas-users/";
  };
});

/**
 * @description: 获取path
 * @return CommonModuleRouter
 */
export const getUserManagementModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: [],
    parentPath: "",
    listName: "",
    listPath: "",
  };
  setUserManagementDetailName(language, route, result);
  return result;
};

/**
 * @description: 获取列表path和name
 * @return CommonModuleRouter
 */
const setUserManagementDetailName = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  result: CommonModuleRouter,
  router?: Router
): void => {
  let strName = "",
    listPath = "/user-management/";
  result.parentName = [language("user.userManagement")];
  if (route.path.includes("system-users")) {
    strName = language("user.systemUsers");
    listPath += "system-users";
  } else if (route.path.includes("saas-users")) {
    strName = language("user.saasUsers");
    listPath += "saas-users";
  }
  if (router) {
    result.listName = strName;
  } else {
    result.currentName = strName;
  }
  result.listPath = listPath;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
export const getUserManagementDetailModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router: Router
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: [],
    parentPath: "",
    listName: "",
    listPath: "",
  };
  switch (router.currentRoute.value.name) {
    case "user-management-saas-users-overview":
      result.currentName = language("user.overview");
      setUserManagementDetailName(language, route, result, router);
      break;
    case "user-management-saas-users-settings":
      result.currentName = language("user.settings");
      setUserManagementDetailName(language, route, result, router);
      break;
    case "user-management-saas-users-permission":
      result.currentName = language("user.permission");
      setUserManagementDetailName(language, route, result, router);
      break;
    case "user-management-sass-users-managed-customers":
      result.currentName = language("user.managedCustomers");
      setUserManagementDetailName(language, route, result, router);
      break;
    case "user-management-sass-users-managed-merchants":
      result.currentName = language("user.managedMerchants");
      setUserManagementDetailName(language, route, result, router);
      break;
    case "user-management-system-users-overview":
      result.currentName = language("user.overview");
      setUserManagementDetailName(language, route, result, router);
      break;
    case "user-management-system-users-settings":
      result.currentName = language("user.settings");
      setUserManagementDetailName(language, route, result, router);
      break;
    case "user-management-system-users-permission":
      result.currentName = language("user.permission");
      setUserManagementDetailName(language, route, result, router);
      break;
    case "user-management-sass-system-managed-customers":
      result.currentName = language("user.managedCustomers");
      setUserManagementDetailName(language, route, result, router);
      break;
    case "user-management-sass-system-managed-merchants":
      result.currentName = language("user.managedMerchants");
      setUserManagementDetailName(language, route, result, router);
      break;
  }
  return result;
};
